<template>
  <VModalWidget
    :dismiss="dismiss"
    :submit="doSubmit"
    :loading="loading"
    :submitting="working"
    :submitTitle="$t('save')"
  >
    <template v-slot:title>{{ $t('title') }}</template>

    <template v-slot:content>
      <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
      <v-form>
        <v-text-field v-model.trim="form.login" :label="$t('login')" :disabled="true" />
        <v-text-field
          v-model.trim="form.email"
          :label="$t('email')"
          :error-messages="emailErrors"
          type="email"
          required
          @input="$v.form.email.$touch()"
          @blur="$v.form.email.$touch()"
          :append-icon="verifiedIcon('email')"
          v-on:keyup.enter="doSubmit"
        />
        <PhoneField v-model="form.phone" :icon="verifiedIcon('phone')" :errors="phoneErrors" />
      </v-form>
    </template>
  </VModalWidget>
</template>

<script>
import { isEmpty } from 'lodash/lang'
import { mapActions } from 'vuex'
import { BaseForm, FormErrors, VModalWidget, PhoneField } from '@argon/app/components'
import { Modal } from '@argon/app/mixins'
import { diffObjects, isIntlPhoneNumber } from '@argon/app/helpers'
import { required, email } from 'vuelidate/lib/validators'
import { NAMESPACE } from '@argon/iam/users/store'

export default {
  name: 'ModalAccount',
  extends: BaseForm,
  mixins: [Modal],
  components: {
    FormErrors,
    VModalWidget,
    PhoneField
  },
  validations: {
    form: {
      phone: { isIntlPhoneNumber },
      email: { required, email }
    }
  },
  props: {
    userId: { type: [String, Number], required: true }
  },
  data: () => ({
    returnPath: { name: 'organizationSettings' },
    form: {},
    loading: true,
    working: false
  }),
  computed: {
    emailErrors() {
      const errors = []
      if (!this.$v.form.email.$dirty) return errors
      !this.$v.form.email.required && errors.push(this.$t('required'))
      !this.$v.form.email.email && errors.push(this.$t('invalid'))
      this.getServerErrors('email', errors)
      return errors
    },
    phoneErrors() {
      const errors = []
      if (!this.$v.form.phone.$dirty) return errors
      !this.$v.form.phone.isIntlPhoneNumber && errors.push(this.$t('phoneWrongFormat'))
      this.getServerErrors('phone', errors)
      return errors
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['getAccount', 'patchAccount']),
    doSubmit() {
      this.resetServerErrors()
      if (this.$v.form.$invalid) {
        return this.$v.form.$touch()
      }
      let data = diffObjects(this.form, this.account)
      if (isEmpty(data)) {
        return this.dismiss()
      }
      this.working = true
      let payload = { userId: this.userId, data }
      this.patchAccount(payload)
        .then((resp) => {
          this.dismiss()
        })
        .catch(this.setServerErrors)
        .finally(() => {
          this.working = false
        })
    },
    verifiedIcon(field) {
      return this.form[`${field}_verified`]
        ? this.$vuetify.icons.values.stateSuccess
        : this.$vuetify.icons.values.statePending
    }
  },
  created() {
    this.getAccount(this.userId).then((account) => {
      this.account = account
      this.form = { ...account }
      this.loading = false
    })
  }
}
</script>

<i18n>
{
  "en": {
    "email": "@:labels.email",
    "invalid": "@:labels.invalid",
    "login": "@:labels.login",
    "phone": "@:labels.phone",
    "phoneWrongFormat": "@:labels.phoneWrongFormat",
    "required": "@:labels.required",
    "save": "@:labels.save",
    "title": "@:labels.account"
  },
  "fr": {
    "email": "@:labels.email",
    "invalid": "@:labels.invalid",
    "login": "@:labels.login",
    "phone": "@:labels.phone",
    "phoneWrongFormat": "@:labels.phoneWrongFormat",
    "required": "@:labels.required",
    "save": "@:labels.save",
    "title": "@:labels.account"
  }
}
</i18n>
